import CallToAction, { CallToActionProps } from "@/components/CallToAction";
import Card, { CardProps } from "./Card";
import {
  Body,
  ButtonsContainer,
  CardsContainer,
  Container,
  Heading,
  TextContainer,
  TextInner,
} from "./styles";

export type TextWithCardsProps = {
  heading: string | null;
  body: string | null;
  cards: CardProps[] | null;
  buttons: ({ _key: string } & CallToActionProps)[] | null;
};

const TextWithCards: React.FC<TextWithCardsProps> = (props) => {
  const { heading, body, cards, buttons } = props;
  return (
    <Container>
      <TextContainer>
        <TextInner>
          {heading && <Heading>{heading}</Heading>}
          {body && <Body>{body}</Body>}
          <ButtonsContainer>
            {buttons?.map((button) => (
              <CallToAction key={button._key} {...button} />
            ))}
          </ButtonsContainer>
        </TextInner>
      </TextContainer>
      <CardsContainer>
        {cards?.map((card) => (
          <Card key={card._key} {...card} />
        ))}
      </CardsContainer>
    </Container>
  );
};

export default TextWithCards;
