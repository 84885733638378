import { H4, P } from "@/lib/styles";
import { bigLabelTypographicStyle } from "@/lib/styles/helpers";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled(H4)`
  margin-bottom: 1rem;
`;

export const Subtitle = styled(P)`
  color: ${({ theme }) => theme.colors.darkMiddle};
  margin-bottom: 1rem;
  padding: 0 0.75rem;
  background-color: ${({ theme }) => theme.colors.white};
`;
