import { imageLoader } from "@/lib/sanity/client";
import { Container } from "./styles";
import Image from "next/image";
import { useState } from "react";
import StickyPointer from "@/components/StickyPointer";
import AppLink, { AppLinkProps } from "@/components/AppLink";

type InternalLink = AppLinkProps & {
  _type: "internalLink";
};

type ExternalLink = {
  _type: "externalLink";
  url: string | null;
  blank: boolean;
};

export type PictureProps = {
  link: InternalLink | ExternalLink;
  asset: {
    url: string;
  };
};

const Picture: React.FC<PictureProps> = (props) => {
  const { asset, link } = props;
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showPointer, setShowPointer] = useState(false);

  const image = (
    <Image
      src={asset.url}
      layout="fill"
      objectFit="cover"
      alt={""} // TODO: should we have this?
      loader={imageLoader}
      onLoadingComplete={() => setLoaded(true)}
      sizes="50vw"
      quality={100}
      draggable={false}
    />
  )

  if (!link) return (
    <Container
      $hasLoaded={loaded}
    >
      {image}
    </Container>
  )

  let href, blank;
  if (link._type === "externalLink" && link.url) {
    href = link.url;
    blank = link.blank;
  }

  const anchor = (
    <Container
      href={href}
      as="a"
      $hasLoaded={loaded}
      onMouseEnter={() => setShowPointer(true)}
      onMouseLeave={() => setShowPointer(false)}
      target={`${blank ? "_blank" : ""}`}
    >
      {image}
    </Container>
  );

  return (
    <>
      <StickyPointer isActive={showPointer} />
      {link._type === "internalLink" && (
        <AppLink page={link.page} params={link.params}>{anchor}</AppLink>
      )}
      {link._type === "externalLink" && anchor}
    </>
  );
};

export default Picture;
