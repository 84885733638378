import styled from "styled-components";
import { H2, P } from "@/lib/styles";

export const Container = styled.div`
  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
  }
`;

export const Heading = styled(H2)`
  margin-bottom: 1.5rem;
`;

export const Body = styled(P)`
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const TextContainer = styled.div`
  ${({ theme }) => theme.mediaQueries.laptop} {
    flex: 1;
    position: sticky;
    height: max-content;
    top: calc(${({ theme }) => theme.space.headerMinHeight} + 2.5rem);
  }
`;

export const TextInner = styled.div`
  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-right: 2rem;
  }
`;

export const CardsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;
