import Picture, { PictureProps } from "./Picture";
import { Container, Subtitle, Title } from "./styles";
import CallToAction, { CallToActionProps } from "./CallToAction/CallToAction";

export type CardProps = {
  _key: string;
  image: PictureProps | null;
  title: string | null;
  subtitle: string | null;
  links: Array<CallToActionProps> | null;
};

const Card: React.FC<CardProps> = (props) => {
  const { image, title, subtitle, links } = props;
  const [link] = links || [];

  return (
    <Container>
      {image && <Picture {...image} link={link} />}
      {title && <Title as="h3">{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {link && <CallToAction {...link} />}
    </Container>
  );
};

export default Card;
