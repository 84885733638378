import AppLink, { AppLinkProps } from "@/components/AppLink";
import Button, { ButtonProps } from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { Wrapper } from "./styles";

type InternalLink = AppLinkProps & {
  _type: "internalLink";
  title: string | null;
};

type ExternalLink = {
  _type: "externalLink";
  title: string | null;
  url: string | null;
  blank: boolean;
};

export type CallToActionProps = InternalLink | ExternalLink;

const CallToAction: React.FC<CallToActionProps> = (props) => {
  const { _type, title } = props;
  if (!title) return null;

  const buttonProps: ButtonProps = {
    as: "a",
    variant: "primaryUnboxed",
    icon: <SVGArrowRight />,
    children: title,
  };

  if (_type === "internalLink" && props.page) {
    return (
      <Wrapper>
        <AppLink page={props.page} params={props.params}>
          <Button {...buttonProps} />
        </AppLink>
      </Wrapper>
    );
  } else if (_type === "externalLink" && props.url) {
    return (
      <Wrapper>
        <Button {...buttonProps} href={props.url} target={`${props.blank ? "_blank" : ""}`}/>
      </Wrapper>
    );
  }
  return null;
};

export default CallToAction;
